import imageCompression from "browser-image-compression";

import { DropOffLocation } from "~/libs/constants";
import logger from "~/libs/logger";

/** Fileのプロパティに設定する圧縮済みフラグのフィールド名 */
const COMPRESSED_FLAG_NAME = "_compressed";

/**
 * 置き配写真や配達票写真の圧縮に使用する標準オプション
 * @type {import("browser-image-compression").Options}
 */
export const STANDARD_COMPRESSION_OPTION = Object.freeze({
  maxSizeMB: 2,
  maxWidthOrHeight: 500,
  useWebWorker: true,
});

const imageFormatUtil = {
  /**
   * 画像のMIMEタイプがimage/jpeg以外の場合にimage/jpegに変換し、変換後の画像を返す
   * 変換できない形式の場合はnullを返す
   * @param {File} photoFile
   * @returns {Promise<File>}
   */
  async formatToJpeg(photoFile) {
    try {
      if (photoFile?.type !== "image/jpeg") {
        const options = {
          ...STANDARD_COMPRESSION_OPTION,
          fileType: "image/jpeg",
        };
        const compressedPhotoFile = await imageCompression(photoFile, options);
        this.setCompressed(compressedPhotoFile, true);
        return compressedPhotoFile;
      }
      return photoFile;
    } catch (error) {
      logger.error(
        "[imageFormatUtil] 画像フォーマットの変換時にエラーが発生しました",
        {
          name: photoFile?.name,
          type: photoFile?.type,
          size: photoFile?.size,
        },
        error,
      );
      return null;
    }
  },

  /**
   * 指定された画像が置き配の写真だった場合にimage/jpegに変換し、変換後の画像を返す
   * @param {File} photoFileForProofOfDelivery
   * @param {number} actualPackageDropPlace
   * @returns {Promise<File>}
   */
  async proofOfDeliveryPhotoChange(
    photoFileForProofOfDelivery,
    actualPackageDropPlace,
  ) {
    if (
      [
        /** @type {number} */ (DropOffLocation.BY_THE_DOOR),
        DropOffLocation.LOCKER,
        DropOffLocation.METERBOX,
        DropOffLocation.MAILBOX,
        DropOffLocation.STORAGE_ROOM,
        DropOffLocation.GARAGE,
        DropOffLocation.BICYCLE_BASKET,
        DropOffLocation.RECEPTION,
      ].includes(actualPackageDropPlace)
    ) {
      // 置き配の写真の場合
      photoFileForProofOfDelivery = await imageFormatUtil.formatToJpeg(
        photoFileForProofOfDelivery,
      );
    }

    return photoFileForProofOfDelivery;
  },

  /**
   * Fileがアプリによって圧縮済かどうかを返す
   * @param {File} imageFile
   * @returns {boolean} 圧縮済かどうか
   */
  isCompressed(imageFile) {
    return imageFile[COMPRESSED_FLAG_NAME] ?? false;
  },
  /**
   * Fileがアプリによって圧縮済かどうかを設定する
   * @param {File} imageFile
   * @param {boolean} compressed
   */
  setCompressed(imageFile, compressed) {
    imageFile[COMPRESSED_FLAG_NAME] = compressed;
  },
};

export default Object.freeze(imageFormatUtil);
